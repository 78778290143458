import React from 'react'
import "./about.css"
const About = () => {
  return (
    <>
  <div className='container'style={{backgroundColor:"pink",marginLeft:"350px",border:"2px solid black ",display:"grid",justifyContent:"center",marginTop:"13.75rem",width:"350px",height:"350px"}} ><h1 className='d-flex justify-content-center'>About Us</h1>
<p><b>
WorkLab App is a kind of app that generally used to maintain our day-to-day tasks or list everything that we have to do, with the most important tasks at the top of the list, and the least important tasks at the bottom
</b></p>
    </div>
    </>
  )
}

export default About
